<template>
  <div class="Task-modal-overlay" @click="close">
      <div class="Task-modal-content" @click.stop>
              <div class="Task-modal-header">
      <div class="Task-modal-dialog">
                  <h5 style="padding: 10px;" class="Task-modal-title">Progress of Trainee (License No: {{ traineeUID }})</h5>
              </div>   
          </div>
          <div>
            <ul class="nav nav-tabs">
                      <li class="nav-item">
                          <a class="nav-link" :class="{ active: currentTab === 'chapters' }"
                              @click.stop="currentTab = 'chapters'">Chapters</a>
                      </li>
                      <!-- <li class="nav-item">
                          <a class="nav-link" :class="{ active: currentTab === 'areas' }"
                          @click.stop="currentTab = 'areas'">Areas</a>
                      </li> -->
                      <li class="nav-item">
                          <a class="nav-link" :class="{ active: currentTab === 'AMTOSS' }"
                          @click.stop="currentTab = 'AMTOSS'">AMTOSS</a>
                      </li>
                  </ul> 
      <table class="data-table" v-if="currentTab === 'chapters'">
        <thead>
        <tr>
          <th style="width: 700px;">Chapter</th>
          <th>Total Tasks</th>
          <th>Mandatory</th>
          <th>Total Complete</th>
          <th>Mandatory Complete</th>
          <th>Enabled</th>
          <th>Outstanding</th>
          <th>% of Mandatory Complete</th>
        </tr>
      </thead>
      <tbody>
        <!-- Table rows for chapters -->
      </tbody>
      <tr v-for="chapter in filteredChapters" :key="chapter.id">
          <td class="first_column"><strong>{{ chapter.chapt_no }}: {{ chapter.name }}</strong></td>
          <td>{{ chapter.tasks.all.length }}</td>
          <td>{{ chapter.tasks.all.length }}</td>
          <td>{{ chapter.tasks.completed.length }}</td>
          <td>{{ chapter.tasks.main_completed.length }}</td>
          <td>{{ chapter.tasks.enabled.length }}</td>
          <td>{{ chapter.tasks.enabled.length - chapter.tasks.completed.length }}</td>
          <td>{{ chapter.tasks.all.length ? (chapter.tasks.main_completed.length / chapter.tasks.all.length * 100).toFixed(1) + '%' : '0.0%' }}</td>
        </tr>
        <tr class="total-row">
          <td class="first_column"><strong>Overall</strong></td>
          <td>{{ totalTasks }}</td>
          <td>{{ totalMandatory }}</td>
          <td>{{ totalCompleted }}</td>
          <td>{{ totalMainCompleted }}</td>
              <td>{{ totalEnabled }}</td>
              <td>{{ totalOutstanding }}</td>
              <td>{{ totalPercentage }}</td>
        </tr>
        <tr class="mandatory-tasks-row">
            <td colspan="8" class="grey-column"><strong>The percentage of mandatory tasks completed must be > 50% (This also applies for each individual chapter)</strong></td>
</tr>
    </table>
    <table class="data-table" v-if="currentTab === 'AMTOSS'">
          <thead>
              <th style="width: 400px;">AMTOSS</th>
              <th>Total Tasks</th>
              <th>Mandatory</th>
              <th>Total Completed</th>
              <th>Mandatory Completed</th>
              <th>Enabled</th>
              <th>Outstanding</th>
              <th>% of Mandatory Complete</th>
          </thead>
      <tbody>

        <tr v-for="(tasks, AMTOSS) in groupedTasksByAMTOSS" :key="AMTOSS">
          <td class="first_column"><strong>{{ AMTOSS }}:</strong></td>
          <td>{{ tasks.all.length }}</td>
          <td>{{ tasks.all.length }}</td>
          <td>{{ tasks.completed.length }}</td>
          <td>{{ tasks.main_completed.length }}</td>
          <td>{{ tasks.enabled.length }}</td>
          <td>{{ tasks.enabled.length - tasks.completed.length }}</td>
          <td>{{ tasks.all.length ? (tasks.main_completed.length / tasks.all.length * 100).toFixed(1) + '%' : '0.0%' }}</td>
        </tr>
        <tr class="total-row">
          <td class="first_column"><strong>Overall</strong></td>
          <td>{{ totalAMTOSSTasks }}</td>
          <td>{{ totalAMTOSSMandatory }}</td>
          <td>{{ totalAMTOSSCompleted }}</td>
          <td>{{ totalAMTOSSMainCompleted }}</td>
              <td>{{ totalAMTOSSEnabled }}</td>
              <td>{{ totalAMTOSSOutstanding }}</td>
              <td>{{ totalAMTOSSPercentage }}</td>
        </tr>
        <tr>
            <td colspan="8" class="grey-column"><strong>The percentage of mandatory tasks completed must be > 50% (This also applies for each individual task type)</strong></td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
      </div>
</template>
<script>
import axios from 'axios';
export default {
  props: {
      mergedTasks: {
    type: Array,
    required: true
  }
  },
  data() {
      return {
          showTaskLongFormModal: true,
          chapters: [],
          currentTab: 'chapters',
          traineeUID: '',
      };
  },
  computed: {
    filteredChapters() {
      return this.chapters.filter(chapter => chapter.tasks.all.length > 0);
    },
    totalTasks() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
    },
    totalMandatory() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
    },
    totalCompleted() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.completed.length, 0);
    },
    totalMainCompleted() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.main_completed.length, 0);
    },
    totalEnabled() {
      return this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.enabled.length, 0);
    },
    totalOutstanding() {
      return this.filteredChapters.reduce((acc, chapter) => acc + (chapter.tasks.enabled.length - chapter.tasks.completed.length), 0);
    },
    totalPercentage() {
      const totalMainCompleted = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.main_completed.length, 0);
      const totalMandatory = this.filteredChapters.reduce((acc, chapter) => acc + chapter.tasks.all.length, 0);
      return ((totalMainCompleted / totalMandatory) * 100).toFixed(1) + '%';
    },
    groupedTasksByAMTOSS() {
              // Group mergedTasks based on AMTOSS
              const groupedAMTOSSTasks = {};
        this.mergedTasks.forEach(task => {
            if (!groupedAMTOSSTasks[task.AMTOSS]) {
                groupedAMTOSSTasks[task.AMTOSS] = {
                    all: [],
                    completed: [],
                    enabled: [],
                    main_completed: [],
                };
            }
            groupedAMTOSSTasks[task.AMTOSS].all.push(task);
            if(task.completed) {
              groupedAMTOSSTasks[task.AMTOSS].completed.push(task);
              groupedAMTOSSTasks[task.AMTOSS].main_completed.push(task);
                }
            if (task.enabled) {
              groupedAMTOSSTasks[task.AMTOSS].enabled.push(task);
            }
              });
      return groupedAMTOSSTasks;
    },
    totalAMTOSSTasks() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => acc + tasks.all.length, 0);
    },
    totalAMTOSSMandatory() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => acc + tasks.all.length, 0);
    },
    totalAMTOSSCompleted() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => acc + tasks.completed.length, 0);
    },
    totalAMTOSSMainCompleted() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => acc + tasks.main_completed.length, 0);
    },
    totalAMTOSSEnabled() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => acc + tasks.enabled.length, 0);
    },
    totalAMTOSSOutstanding() {
      return Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => {
        const totalCompleted = tasks.completed.length;
        const totalEnabled = tasks.enabled.length;
        return acc + (totalEnabled - totalCompleted);
      }, 0);
    },
    totalAMTOSSPercentage() {
      const totalMainCompleted = Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => {
        return acc + tasks.main_completed.length;
      }, 0);
      const totalMandatory = Object.values(this.groupedTasksByAMTOSS).reduce((acc, tasks) => {
        return acc + tasks.all.length;
      }, 0);
      return ((totalMainCompleted / totalMandatory) * 100 || 0).toFixed(1) + '%';
    },
  },
  watch: {
    mergedTasks(newVal) {
      console.log('New value of mergedTasks:', newVal);
    }
  },
  methods: {
    async fetchTrainee () {
      const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    const traineeId = localStorage.getItem('trainee_id');
    console.log('id');
    console.log(traineeId);
    try {
        // Assuming you have an API endpoint to fetch task data
        const response = await axios.get(`/api/users/${traineeId}`, { headers });
        const traineeData = response.data;
    console.log('data');
        console.log(traineeData);
        this.traineeUID = traineeData.UIN;
      } catch (error) {
        console.error('Error fetching trainee data:', error);
        // Handle errors as needed
    }
    },
      close() {
          this.$emit('close');
      },  
      async fetchChapters() {
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };
    try {
        // Assuming you have an API endpoint to fetch task data
        const response = await axios.get(`/api/chapters`, { headers });
        const chaptersData = response.data;
        
        // Group mergedTasks by chapter based on chapt_no
        const groupedTasks = {};
        this.mergedTasks.forEach(task => {
            if (!groupedTasks[task.chapt_no]) {
                groupedTasks[task.chapt_no] = {
                    all: [],
                    completed: [],
                    enabled: [],
                    main_completed: [],
                };
            }
            groupedTasks[task.chapt_no].all.push(task);
            if (task.enabled) {
              groupedTasks[task.chapt_no].enabled.push(task);
            }
            if(task.completed) {
                  groupedTasks[task.chapt_no].main_completed.push(task);
                  groupedTasks[task.chapt_no].completed.push(task);
                }
        });
        // Merge chaptersData with groupedTasks
        this.chapters = chaptersData.map(chapter => {
            const tasks = groupedTasks[chapter.chapt_no] || { all: [], completed: [], enabled: [], main_completed: [], };
            return {
                ...chapter,
                tasks: {
                    all: tasks.all,
                    completed: tasks.completed,
                    enabled: tasks.enabled,
                    main_completed: tasks.main_completed,
                }
            };
        });
        console.log(this.chapters);
    } catch (error) {
        console.error('Error fetching task data:', error);
        // Handle errors as needed
    }
},
  },
  async mounted() {
  this.fetchChapters();
  this.fetchTrainee();
  console.log('Value of mergedTasks:', this.mergedTasks);
},
}
</script>

<style scoped>
.Task-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(146, 146, 146, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.Task-modal-content {
  margin-top: 40px;
  background-color: rgb(239, 244, 255);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  width: min(95%, 1200px);
  max-height: 85vh; /* You can adjust the maximum height as needed */
  min-height: 85vh;
  overflow-y: auto;
  border: 2px solid rgb(83, 83, 83);
  margin-top: 80px;
}
.Task-modal-title {
  margin-bottom: 10px;
  float: left;
  margin-left: 20px;
  color: black;
}
.data-table {
  border-collapse: collapse;
  background-color: rgb(253, 253, 255);
  border: 2px solid rgb(0, 0, 0);
  box-shadow: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.data-table td, .data-table th {
  border: 1px solid grey;
  padding: 10px;
  width: 250px;
  font-size: 16px;
  background-color: white;
}
.data-table th, td.grey-column {
  background-color: rgb(233, 233, 233)
}
.total-row, .mandatory-tasks-row {
    border-top: 2px solid black; /* Adjust the border color and size as needed */
}
</style>