<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<template>
  <div class="Evaluation-modal-overlay">
    <div class="Evaluation-modal">
      <h3 class="Evaluation-modal-title"><b>Task Assessment</b> (Task ID: {{ taskId }})</h3>
      <div class="artificial-fieldset">
        <label class="artificial-fieldset-legend">Assessment Info</label>
        <table class="additional-info-table">
          <tr>
            <td>
              <strong>Trainee Name:</strong>
            </td>
            <td>
              <input type="text" v-model="traineeName" :disabled="isSubmitted > 0">
            </td>
            <td>
              <strong>Practical Assessor:</strong>
            </td>
            <td>
              <input type="text" v-model="assessorName" :disabled="isSubmitted > 0">
            </td>
          </tr>
          <tr>
            <td>
              <strong>Trainee ID No:</strong>
            </td>
            <td>
              <input type="text" v-model="traineeId" :disabled="isSubmitted > 0">
            </td>
            <td>
              <strong>Assessment location:</strong>
            </td>
            <td>
              <input type="text" v-model="assessmentLocation" :disabled="isSubmitted > 0">
            </td>
          </tr>
          <tr>
            <td>
              <strong>Class/Group:</strong>
            </td>
            <td>
              <input type="text" v-model="projectId" :disabled="isSubmitted > 0">
            </td>
          </tr>
          <tr>
            <td colspan="1">
              <strong>Description of Task being Assessed:</strong>
            </td>
            <td colspan="3">
              <textarea v-model="taskDesc" style="width: 100%; height: 70px;" :disabled="isSubmitted > 0"></textarea>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Aircraft Registration:</strong>
            </td>
            <td>
              <input type="text" v-model="acReg" :disabled="isSubmitted > 0">
            </td>
            <td>
              <strong>Task Card No.</strong>
            </td>
            <td>
              <input type="text" v-model="taskCardNo" :disabled="isSubmitted > 0">
            </td>
          </tr>
        </table>
      </div>
      <table class="assessment-table">
        <thead style="border: 2px solid rgb(71, 71, 71)">
          <tr>
            <th rowspan="3" style="border: 2px solid rgb(71, 71, 71)">Assessment Criteria</th>
            <th colspan="5" style="border: 2px solid rgb(71, 71, 71)">Grading</th>
          </tr>
          <tr>
            <th colspan="2" style="color: rgb(192, 1, 1); border: 2px solid rgb(71, 71, 71);">Unsatisfactory</th>
            <th colspan="3" style="color: rgb(0, 113, 219); border: 2px solid rgb(71, 71, 71);">Satisfactory</th>
          </tr>
          <tr>
            <th style="width: 9%; color: rgb(228, 0, 0)">1</th>
            <th style="width: 9%; color: rgb(240, 156, 0); border-right: 2px solid rgb(71, 71, 71);">2</th>
            <th style="width: 9%; color: rgb(0, 84, 163);">3</th>
            <th style="width: 9%; color: rgb(3, 133, 255);">4</th>
            <th style="width: 9%; color: rgb(109, 0, 233);">5</th>
          </tr>
        </thead>
        <tbody style="border: 2px solid rgb(71, 71, 71)">
          <!-- Inside the <tbody> tag -->
          <tr v-for="(item) in criteriaResults" :key="item.criterionId">
            <td style="border-right: 2px solid rgb(71, 71, 71);">{{ criteria[item.criterionId - 1] }}</td>
            <td @click="selectRadio(item.criterionId, 1)">
              <label class="grading" :for="`unsatisfactory${item.criterionId}`" style="color: rgb(228, 0, 0);">1</label>
              <input type="radio" :id="`unsatisfactory${item.criterionId}`" :name="`result${item.criterionId}`"
                value="1" v-model="item.result" :disabled="isSubmitted > 0">
            </td>
            <td @click="selectRadio(item.criterionId, 2)" style="border-right: 2px solid rgb(71, 71, 71);">
              <label class="grading" :for="`unsatisfactory${item.criterionId}`"
                :style="getLabelStyle(item.criterionId, 2)">2</label>
              <input type="radio" :id="`unsatisfactory${item.criterionId}`" :name="`result${item.criterionId}`"
                value="2" v-model="item.result" :checked="getLabelStyle(item.criterionId, 2).isChecked"
                :disabled="isSubmitted > 0">
            </td>
            <td @click="selectRadio(item.criterionId, 3)">
              <label class="grading" :for="`satisfactory${item.criterionId}`"
                :style="getLabelStyle(item.criterionId, 3)">
                <span style="border: none;"
                  v-if="criteria[item.criterionId - 1] === 'Approved / Acceptable Data' || criteria[item.criterionId - 1] === 'Tooling / Test Equipment' || criteria[item.criterionId - 1] === 'Standard Practices' || criteria[item.criterionId - 1] === 'Measurements / Adjustments'" class="disabled">-</span>
                <span v-else style="border: none;">3</span>
              </label>
              <input
                v-if="!(criteria[item.criterionId - 1] === 'Approved / Acceptable Data' || criteria[item.criterionId - 1] === 'Tooling / Test Equipment' || criteria[item.criterionId - 1] === 'Standard Practices' || criteria[item.criterionId - 1] === 'Measurements / Adjustments')"
                type="radio" :id="`satisfactory${item.criterionId}`" :name="`result${item.criterionId}`" value="3"
                v-model="item.result" :checked="getLabelStyle(item.criterionId, 3).isChecked"
                :disabled="isSubmitted > 0">
                <input v-else type="radio" disabled style="display: none;">
            </td>
            <td @click="selectRadio(item.criterionId, 4)">
              <label class="grading" :for="`satisfactory${item.criterionId}`" style="color: rgb(3, 133, 255);">
                <u v-if="criteria[item.criterionId - 1] === 'Measurements / Adjustments'">4</u>
                <span style="border: none;" v-else>4</span></label>
              <input type="radio" :id="`satisfactory${item.criterionId}`" :name="`result${item.criterionId}`" value="4"
                v-model="item.result" :checked="getLabelStyle(item.criterionId, 4).isChecked"
                :disabled="isSubmitted > 0">
            </td>
            <td @click="selectRadio(item.criterionId, 5)">
              <label class="grading" :for="`satisfactory${item.criterionId}`" style="color: rgb(109, 0, 233);">5</label>
              <input type="radio" :id="`satisfactory${item.criterionId}`" :name="`result${item.criterionId}`" value="5"
                v-model="item.result" :disabled="isSubmitted > 0">
            </td>
          </tr>
          <tr style="border-top: 2px solid black;">
            <td>
              Sum of gradings (Assign the underlined value to each criterion not evaluated)
            </td>
            <td colspan="2" style="border-left: 2px solid black; border-right: 2px solid black;">

            </td>
            <td colspan="3">
              <b>{{ sumOfGradings }}</b>
            </td>
          </tr>
          <tr>
            <td>
              “Fail” if the examinee attains a grading of 1 or 2 in any criterion
            </td>
            <td colspan="2" style="border-left: 2px solid black; border-right: 2px solid black;">
              <b>>0</b>
            </td>
            <td colspan="3">
              <b>N/A</b>
            </td>
          </tr>
          <tr>
            <td>
              “Pass” if the examinee attains a score of 40 or more, and no “Fail” =0 ≥ 40
            </td>
            <td colspan="2" style="border-left: 2px solid black; border-right: 2px solid black;">
              <b>=0</b>
            </td>
            <td colspan="3">
              <b>≥ 40</b>
            </td>
          </tr>
          <tr style="border-top: 2px solid black;">
            <td colspan="6" style="border-bottom: 1px solid white;">
              Describe how the candidate demonstrated a “Responsible Attitude” in identifying and mitigating risks to
              the safety of personnel and to the airworthiness of the aircraft.
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <textarea v-model="attitude" style="width: 100%; height: 70px;" :disabled="isSubmitted > 0"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="grading-table-container">
        <table class="grading-table" style="border: 2px solid black; font-size: 12px;">
          <tbody>
            <tr>
              <td><strong>Grading</strong></td>
              <td v-for="(percentage, index) in gradingTable" :key="index"
                :class="{ 'highlighted': sumOfGradings >= percentage.min && sumOfGradings <= percentage.max }">{{
        percentage.grade }}</td>
            </tr>
            <tr>
              <td><strong>%</strong></td>
              <td v-for="(percentage, index) in gradingTable" :key="index"
                :class="{ 'highlighted': sumOfGradings >= percentage.min && sumOfGradings <= percentage.max }">{{
        percentage.percentage }}</td>
            </tr>
          </tbody>
        </table>
        <table class="assessment-table" style="margin-top: 5px; margin-bottom: 20px;">
          <tbody style="border: 2px solid black;">
            <tr style="border-top: 2px solid black;">
              <td style="border-bottom: 1px solid white;">
                If applicable, identify any follow-up to this assessment that must be carried out.
              </td>
            </tr>
            <tr>
              <td>
                <textarea v-model="followUp" style="width: 100%; height: 90px;" :disabled="isSubmitted > 0"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="background-color:#f2f2f2; border: 1px solid #8080806b; padding: 8px;">
        <div class="assessment-result">
          <table>
            <td style="width:50%; font-weight: bold;">
              RESULT OF THE ASSESSMENT
            </td>
            <td style="border:0px;" :style="assessmentResultStyle">
              <span style="border:0px" v-if="assessmentResult">{{ assessmentResult.toUpperCase() }}</span>
              <span v-else style="border:none">-</span>
            </td>
          </table>
        </div>
        <div class="assessment-container">
          <div class="signature-block">
            <table class="signature-table">
              <tr class="trainee-signature">
                <td style="width:50%; font-weight:bold;">TRAINEE SIGNATURE:</td>
                <td>
                  <canvas ref="traineeSignaturePadCanvas" id="trainee-signature-pad" class="signature-pad" width="270"
                    height="60"></canvas>
                </td>
              </tr>
              <tr class="signature">
                <td style="font-weight:bold">ASSESSOR SIGNATURE:</td>
                <td>
                  <canvas ref="assessorSignaturePadCanvas" id="assessor-signature-pad" class="signature-pad" width="270"
                    height="60"></canvas>
                </td>
              </tr>
              <tr class="signature">
                <td style="font-weight:bold">DATE:</td>
                <td><input type="date" id="date" name="date" v-model="date" :disabled="isSubmitted > 0" /></td>
              </tr>
            </table>
          </div>
        </div>
        <!-- ... other signature inputs ... -->
      </div>
      <div class="button-container">
        <button class="ins-close-button" @click="close">Close</button>
        <button class="ins-clear-button"  @click="clearSignature" :disabled="isSubmitted > 1">Clear Signature</button>
        <button class="ins-submit-button" @click="updateTraineeSignature" :disabled="isSubmitted > 1">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SignaturePad from 'signature_pad';

export default {
  props: {
    taskId: {
      type: [String, Number],
      required: true
    },
    tasksCount: {
      type: Number,
      required: true
    },
    completedTasks: {
      type: Number,
    },
    fetchedData: Object,
    taskAssessmentId: Number,
  },
  data() {
    return {
      criteriaResults: new Array(12).fill(null).map((_, index) => {
        return { criterionId: index + 1, result: null };
      }),
      isSubmitted: false,
      tasksPerformed: '',
      traineeSignature: '',
      assessorSignature: '',
      traineeName: '',
      assessorName: '',
      assessmentLocation: '',
      acReg: '',
      taskDesc: '',
      taskCardNo: '',
      attitude: '',
      grade: '',
      percentage: '',
      followUp: '',
      projectId: '',
      successfulChecked: false,
      remedialChecked: false,
      date: '',
      traineeId: localStorage.getItem('trainee_id'),
      logbookId: localStorage.getItem('logbook_id'),
      instructorId: localStorage.getItem('instructor_id'),
      evallogbookId: null,
      signaturePad: null,
      traineeSignaturePad: null,
      criteria: [
        'Risk Evaluation and Response',
        'Approved / Acceptable Data',
        'Tooling / Test Equipment',
        'Standard Practices',
        'Component Location',
        'Removal / Disassembly',
        'Fitment / Assembly',
        'Measurements / Adjustments',
        'Inspection / Function Test',
        'Safety Practices',
        'Cleanliness',
        'Task Finish / Close-Up'
      ],
      gradingTable: [
        { grade: 'N/A', percentage: '0', min: 0, max: 39 },
        { grade: '40', percentage: '75.0', min: 40, max: 40 },
        { grade: '41', percentage: '76.3', min: 41, max: 41 },
        { grade: '42', percentage: '77.5', min: 42, max: 42 },
        { grade: '43', percentage: '78.8', min: 43, max: 43 },
        { grade: '44', percentage: '80.0', min: 44, max: 44 },
        { grade: '45', percentage: '81.3', min: 45, max: 45 },
        { grade: '46', percentage: '82.5', min: 46, max: 46 },
        { grade: '47', percentage: '83.8', min: 47, max: 47 },
        { grade: '48', percentage: '85.0', min: 48, max: 48 },
        { grade: '49', percentage: '86.3', min: 49, max: 49 },
        { grade: '50', percentage: '87.5', min: 50, max: 50 },
        { grade: '51', percentage: '88.8', min: 51, max: 51 },
        { grade: '52', percentage: '90.0', min: 52, max: 52 },
        { grade: '53', percentage: '91.3', min: 53, max: 53 },
        { grade: '54', percentage: '92.5', min: 54, max: 54 },
        { grade: '55', percentage: '93.8', min: 55, max: 55 },
        { grade: '56', percentage: '95.0', min: 56, max: 56 },
        { grade: '57', percentage: '96.3', min: 57, max: 57 },
        { grade: '58', percentage: '97.5', min: 58, max: 58 },
        { grade: '59', percentage: '98.8', min: 59, max: 59 },
        { grade: '60', percentage: '100.0', min: 60, max: 60 },
      ],
    }
  },
  watch: {
    fetchedData(newVal) {
      console.log('Received fetched data:', newVal);
      this.populateTaskAssessmentData(newVal);
    },
    criteriaResults: {
      immediate: true,
      handler() {
        const result = this.assessmentResult;
        this.successfulChecked = result === 'successful';
        this.remedialChecked = result === 'remedial';
      }
    },
    showTaskAssessmentFormModal(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initializeSignaturePad();
        });
      } else {
        this.destroySignaturePad();
      }
    },
  },
  computed: {
    sumOfGradings() {
      // Check if any criterion is marked as 'unsatisfactory'
      const allGradesSatisfactory = this.criteriaResults.every(item => [3, 4, 5].includes(Number(item.result)));

      if (allGradesSatisfactory) {
        // If there are no unsatisfactory results, calculate the sum as before
        return this.criteriaResults.reduce((sum, item) => {
          // Convert item.result to a number and add it to the sum
          return sum + (item.result ? parseInt(item.result) : 0);
        }, 0);
      } else {
        return 0;
      }
    },
    assessmentResult() {
      // Check if all criteria are marked as 'satisfactory'
      const allSatisfactory = this.criteriaResults.every(item => [3, 4, 5].includes(Number(item.result)));

      // Check if any criterion is marked as 'unsatisfactory'
      const anyUnsatisfactory = this.criteriaResults.some(item => [1, 2].includes(Number(item.result)));

      if (allSatisfactory) {
        return 'successful';
      } else if (anyUnsatisfactory) {
        return 'remedial';
      } else {
        return 'TBD'; // or any other default value based on your needs
      }
    },
    assessmentResultStyle() {
      switch (this.assessmentResult) {
        case 'successful':
          return { color: 'green', fontWeight: 'bold', border: 'none' };
        case 'remedial':
          return { color: 'red', fontWeight: 'bold', border: 'none' };
        default:
          return {};
      }
    },
    isReadOnly() {
      return this.fetchedData && this.fetchedData.is_submitted;
    },
    isCheckboxDisabled() {
      return this.isSubmitted > 0;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getDefaultValue(criterionId) {
      const criterion = this.criteria[criterionId - 1];
      // Determine default value based on criterion or any other logic
      if (['Risk Evaluation and Response', 'Approved / Acceptable Data', 'Tooling / Test Equipment', 'Standard Practices', 'Safety Practices', 'Cleanliness', 'Task Finish / Close-Up'].includes(criterion)) {
        return 2;
      } else if (['Component Location', 'Removal / Disassembly', 'Fitment / Assembly', 'Inspection / Function Test'].includes(criterion)) {
        return 3;
      } else if (criterion === 'Measurements / Adjustments') {
        return 4;
      } else {
        return null;
      }
    },
    getLabelStyle(criterionId, value) {
      const isUnderlined = this.shouldUnderline(criterionId, value);
      let color;
      let isChecked = isUnderlined;

      if (value === 2) {
        color = 'rgb(240, 156, 0)';
      } else if (value === 3) {
        color = 'rgb(0, 84, 163)';
      } else if (value === 4) {
        color = 'rgb(3, 133, 255)';
      } else {
        color = null;
      }

      return {
        color,
        textDecoration: isUnderlined ? 'underline' : null,
        border: 'none',
        isChecked,
      };
    },
    shouldUnderline(criterionId, value) {
      const criterion = this.criteria[criterionId - 1];
      return (
        (value === 2 &&
          ['Risk Evaluation and Response', 'Approved / Acceptable Data', 'Tooling / Test Equipment', 'Standard Practices', 'Safety Practices', 'Cleanliness', 'Task Finish / Close-Up'].includes(criterion)) ||
        (value === 3 &&
          ['Component Location', 'Removal / Disassembly', 'Fitment / Assembly', 'Inspection / Function Test'].includes(criterion)) ||
        (value === 4 && criterion === 'Measurements / Adjustments')
      );
    },
    selectRadio(criterionId, value) {
  // Check if the criteria at criterionId - 1 matches the specified conditions
  if (value === 3 && (this.criteria[criterionId - 1] === 'Approved / Acceptable Data' || 
      this.criteria[criterionId - 1] === 'Tooling / Test Equipment' || 
      this.criteria[criterionId - 1] === 'Standard Practices' || 
      this.criteria[criterionId - 1] === 'Measurements / Adjustments')) {
    // Do not proceed with the click
    return;
  }

  // Find the corresponding item in criteriaResults array
  const selectedItem = this.criteriaResults.find(item => item.criterionId === criterionId);

  if (selectedItem) {
    // Update the 'result' property of the selected item
    selectedItem.result = value;
  }
},
clearSignature() {
      if (this.isSubmitted === 1 && this.traineeSignaturePad) {
        this.traineeSignaturePad.clear();
      }
    },
    initializeSignaturePad() {
            // Initialize the trainee's signature pad
            const traineeCanvas = this.$refs.traineeSignaturePadCanvas;      
        if (traineeCanvas) {
          this.traineeSignaturePad = new SignaturePad(traineeCanvas, {
            backgroundColor: 'rgb(255, 255, 255)',
          });
          if (this.traineeSignature) {
          this.traineeSignaturePad.fromDataURL(this.traineeSignature, {width: 270, height: 60});
        }
              // Check if is_submitted is 2, then disable the canvas
              
    if (this.isSubmitted > 1) {
      this.traineeSignaturePad.off();
      traineeCanvas.style.opacity = '0.5'; // Change opacity to indicate it's disabled
    }
        }
      const assessorCanvas = this.$refs.assessorSignaturePadCanvas;
      if (assessorCanvas) {
        this.assessorSignaturePad = new SignaturePad(assessorCanvas, {
          backgroundColor: 'rgb(255, 255, 255)',
        });

        if (this.assessorSignature) {
          this.assessorSignaturePad.fromDataURL(this.assessorSignature, {width: 270, height: 60});
        }

        if (this.isSubmitted > 0) {
          this.assessorSignaturePad.off();
          assessorCanvas.style.opacity = '0.5';
        } 
      }
    },
    destroySignaturePad() {
      if (this.signaturePad) {
        this.signaturePad.off();  // Turn off the signature pad
        this.signaturePad = null; // Clean up the reference
      }
    },

    toggleAllSatisfactory(event) {
      const isSatisfactory = event.target.checked;
      this.criteriaResults.forEach((criteria) => {
        criteria.result = isSatisfactory ? 'satisfactory' : null;
      });
    },
    populateTaskAssessmentData(TaskAssessmentData) {
      if (!TaskAssessmentData) {
        console.error('No evaluation data found');
        this.criteriaResults = new Array(12).fill(null).map((_, index) => {
      return { criterionId: index + 1, result: null };
    });
    this.criteriaResults.forEach(item => {
      const defaultValue = this.getDefaultValue(item.criterionId);
      if (defaultValue !== null) {
        item.result = defaultValue;
      }
    });
    this.assessmentLocation = '';
    this.taskDesc = '';
    this.acReg = '';
    this.taskCardNo = '';
    this.attitude = '';
    this.followUp = '';
    this.traineeSignature = '';
    this.assessorSignature = '';
    this.date = '';
    this.isSubmitted = TaskAssessmentData.is_submitted;
        return;
      }

      // Populate input fields with data from TaskAssessmentData
      this.taskEvalId = TaskAssessmentData.id || '';
      this.traineeName = TaskAssessmentData.trainee_name || ''; // Populate trainee name
      this.assessorName = TaskAssessmentData.assessor_name || ''; // Populate assessor name
      this.traineeId = TaskAssessmentData.trainee_id || ''; // Populate trainee ID
      this.assessmentLocation = TaskAssessmentData.location || ''; // Populate assessment location
      this.projectId = TaskAssessmentData.project_id || ''; // Populate project ID
      this.taskDesc = TaskAssessmentData.task_desc || ''; // Populate task description
      this.acReg = TaskAssessmentData.ac_reg || ''; // Populate aircraft registration
      this.taskCardNo = TaskAssessmentData.task_card_no || ''; // Populate task card number
      this.attitude = TaskAssessmentData.attitude || ''; // Populate attitude description
      this.followUp = TaskAssessmentData.follow_up || ''; // Populate follow-up


      // Convert criteria object to an array if necessary
      if (TaskAssessmentData.criteria && typeof TaskAssessmentData.criteria === 'object') {
        // Object.entries will give you an array of [key, value] pairs
        this.criteriaResults = Object.entries(TaskAssessmentData.criteria).map(([criterionId, result]) => ({
          criterionId: parseInt(criterionId), // Make sure to parse the criterionId to number if necessary
          result: result
        }));
      }

      // Check if this.signaturePad exists before using it
      if (this.assessorSignaturePad && TaskAssessmentData.assessor_signature) {
        const image = new Image();
        image.onload = () => {
          if (this.assessorSignaturePad) {
            this.assessorSignaturePad.fromDataURL(TaskAssessmentData.assessor_signature, {width: 270, height: 60});
          }
        };
        image.src = TaskAssessmentData.assessor_signature;
      } else if (this.signaturePad) {
        this.signaturePad.clear();
      }

      if (this.traineeSignaturePad) {
        const traineeImage = new Image();
        traineeImage.onload = () => {
          if (this.traineeSignaturePad) {
            this.traineeSignaturePad.fromDataURL(TaskAssessmentData.trainee_signature, {width: 270, height: 60});
          }
        };
        traineeImage.src = TaskAssessmentData.trainee_signature;
      }
      console.log(TaskAssessmentData);

      // Assign other values
      this.successfulChecked = TaskAssessmentData.result === 'successful';
      this.remedialChecked = TaskAssessmentData.result === 'remedial';
      this.traineeSignature = TaskAssessmentData.trainee_signature;
      this.assessorSignature = TaskAssessmentData.assessor_signature;
      this.date = TaskAssessmentData.date;
      this.isSubmitted = TaskAssessmentData.is_submitted;
    },
    updateTraineeSignature() {
  // Confirm submission
  const confirmSubmission = confirm("Once submitted, the form cannot be edited. Are you sure you want to submit?");
  if (confirmSubmission) {
    const TaskAssessmentUpdateData = {
      logbook_id: Number(this.logbookId),
      trainee_id: Number(this.traineeId),
      project_id: Number(this.projectId),
      task_id: Number(this.taskId),
      is_submitted: 2,
      trainee_signature: this.traineeSignaturePad.toDataURL()
    };

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${token}` };

    // First PUT request to update task assessment signature
    axios.put(`/api/task_assessments/${this.taskEvalId}`, TaskAssessmentUpdateData, { headers })
      .then(response => {
        this.isSubmitted = true;  // Assuming the task assessment is now considered submitted
        this.populateTaskAssessmentData(response.data);  // Refresh data with updated values if necessary
        const taskUpdateData = {
          is_assessed: 1
        };

      return axios.put(`/api/logbookentry/${this.logbookId}/${this.taskId}`, taskUpdateData, { headers });
      })
      .then(response => {
        alert('Signature updated successfully.');
        console.log(response);
        this.close();  // Close the form/modal after submission
      })
      .catch(error => {
        console.error('There was an error updating the signature or marking task as assessed:', error);
        alert('Failed to update the task or signature.');
      });
  }
},
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeSignaturePad();
    });
    if (this.fetchedData) {
      this.populateTaskAssessmentData(this.fetchedData);
    }
    const traineeName = localStorage.getItem('traineeName');
    const assessorName = localStorage.getItem('username');
    const traineeId = localStorage.getItem('trainee_id');

    this.traineeName = traineeName;
    this.assessorName = assessorName;
    this.traineeId = traineeId;
  },
  beforeUnmount() { // or beforeDestroy() for Vue 2
    this.destroySignaturePad();
  }

}
</script>


<style scoped>
.Evaluation-modal-overlay {
  position: absolute;
  left: 0;
  background-color: rgba(97, 94, 94, 0.5);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;

}

.Evaluation-modal {
  background-color: #ffffff;
  overflow-y: auto;
  /* Enables vertical scrolling if needed */
  margin-top: 2px;
  max-height: 85vh;
  max-width: 90vw;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  padding: 20px;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.assessment-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5px;
  align-items: center;
}

.assessment-table th,
.assessment-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.assessment-table thead th {
  background-color: #f2f2f2;
}

.signature-section {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.signature-item label {
  width: 40%;
  display: block;
  margin-bottom: 5px;
}

.signature-placeholder,
.date-placeholder {
  border: 1px solid #ddd;
  padding: 5px;
  min-height: 30px;
  /* Adjust as needed */
}

.button-container {
  display: flex;
  justify-content: flex-start;
  /* Aligns items to the right */
  align-items: center;
  margin-top: 20px;
  /* Adjust as needed */
}

.ins-save-button {
  background-color: #0b3a05;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  width: 100%;
}

.ins-submit-button {
  background-color: #3a0505;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 20px;
  width: 150px;
}

.ins-close-button {
  background-color: #a52c0d;
  color: white;
  padding: 5px 20px;
  border-radius: 5px;
}

.ins-clear-button {
  background-color: #f0ac9c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 200px;
  margin-left: 60%;
  margin-right: 0;
  width: 100%
}

label.grading {
  font-weight: bold;
  padding-right: 8px;
  margin-bottom: 0px;
}

.ins-save-button:disabled,
.ins-submit-button:disabled,
.ins-clear-button:disabled {
  background-color: rgb(216, 216, 216);
  cursor: not-allowed;
  color: grey
}


.assessment-result {
  margin-left: 25%;
}

.task-performance {
  width: 50%;
  border-collapse: collapse;
  text-align: left;
  background-color: #f2f2f2;
  margin-left: 20px;
}

.task-performance th,
.task-performance td {
  border: 1px solid black;

}

.task-performance th {
  /* Adjust the color to match your header background */
  font-weight: bold;
}


.task-performance input[type="text"] {
  width: 100%;
  border: none;
  padding: 4px;
  text-align: center;
  background-color: #f2f2f2;
}

.assessment-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}


.signature-block {
  display: flex;
  flex-direction: column;
  margin-right: 70px;
}


.signature-table {
  width: 100%;
}

.signature-table td {
  padding: 4px;
  /* Adjust padding as needed */
  vertical-align: middle;
  /* Aligns content of <td> vertically in the middle */
}

.signature-table .signature {
  text-align: left;
  /* Aligns the text to the left */
}

.trainee-signature {
  text-align: left;
}

.signature-table input[type="text"],
.signature-table input[type="date"] {
  width: 100%;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
  background-color: transparent;
  outline: none;
  height: 20px;
}

.artificial-fieldset {
  border: 1px solid #8b8b8b;
  padding: 10px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
}

.artificial-fieldset-legend {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #e4e4e4;
  padding: 0 10px;
  font-weight: bold;
  border: 1px solid #8b8b8b;
  border-radius: 3px;
}

.button-container {
  margin-bottom: 10px;
}

.additional-info-table {
  text-align: left;
  margin-top: 10px;
}

.additional-info-table td:nth-child(1) {
  width: 20%
}

.additional-info-table td:nth-child(2) {
  width: 28%;
  padding-right: 5%;
}

.additional-info-table td:nth-child(3) {
  width: 29%;
  padding-left: 10%;
}

.additional-info-table td:nth-child(4) {
  width: 30%;
}

.additional-info-table input {
  height: 25px;
}

label {
  margin-bottom: 0px;
}

.additional-info-table td {
  padding: 5px;
}


@media (max-width: 768px) {

  /* Adjust the max-width as needed for iPad or other tablet sizes */
  .Evaluation-modal {
    padding: 5px;
    font-size: 0.9em;
    /* Reduce font size */
  }

  /* Further adjustments for other elements */
}


#selectAllSatisfactory {
  margin-left: 5px;
  border: 1px solid rgb(206, 13, 13);
}

.signature-pad {
  border-bottom: 1px solid #000;
  border-radius: 5px;
}

.grading-table {
  width: 100%;
  border-collapse: collapse;
}

.grading-table th,
.grading-table td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

.highlighted {
  background-color: #ffe066;
  /* Change the background color as per your preference */
}

:disabled {
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
}
</style>
