import dashBoard from "./components/Examiner/ExaminerDashboard.vue";
import viewProducts from "./components/Examiner/products/products.vue";
import productPage from "./components/Examiner/products/productPage.vue";
import importTasksPage from "./components/Examiner/products/ImportTasks/ImportTasks.vue";
import importTasksB2 from "./components/Examiner/products/ImportTasks/ImportTasksB2.vue";
import IssuesDisplay from "./components/Examiner/products/ImportTasks/issuesDisplay.vue";
import IssuesDisplayB2 from "./components/Examiner/products/ImportTasks/issuesDisplayB2.vue";
import importSuccessful from "./components/Examiner/products/ImportTasks/importSuccessful.vue";
import importSuccessfulB2 from "./components/Examiner/products/ImportTasks/importSuccessfulB2.vue";
import { createRouter, createWebHistory } from "vue-router";
import Login from "./components/Examiner/userLogin.vue";
import TemporaryLogin from "./components/Examiner/TemporaryLogin.vue";
import AddUser from "./components/Examiner/AddUser.vue";
import ViewUsers from "./components/Examiner/ViewUsers.vue";
//import AddTrainee from './components/Examiner/AddTrainee.vue';
//import AddInstructor from "./components/Examiner/AddInstructor.vue";
//import ViewTasks from "./components/Examiner/ViewTasks.vue";
//import EditTrainee from "./components/Examiner/EditTrainee.vue";
//import EditInstructor from "./components/Examiner/EditInstructor.vue";
import TimeOut from "./components/Examiner/TimeOut.vue";
import ExaminerHelp from './components/Examiner/ExaminerHelp.vue';

import TraineeSignature from "./components/Examiner/TraineeSignature.vue";

import InstructorHome from './components/Instructor/InstructorHome.vue';
import GenerateQR from './components/Instructor/GenerateQR.vue';
import InstructorLogbook from './components/Instructor/InstructorLogbook.vue';
import MultiSignOff from './components/Instructor/MultiSignOff.vue';

import TraineeHome from "./components/Trainee/TraineeHome.vue";
import TraineeLogbook from "./components/Trainee/TraineeLogbook.vue";

import AddProject from './components/Examiner/projects/AddProject.vue';
import ViewProjects from './components/Examiner/projects/ViewProjects.vue';
import ViewTrainees from './components/Examiner/projects/ViewTrainees.vue';
import ViewInstructors from './components/Examiner/projects/ViewInstructors.vue';
import ViewAssessors from './components/Examiner/projects/ViewAssessors.vue';
import AssignTrainees from './components/Examiner/projects/AssignTrainees.vue';
import AssignInstructors from './components/Examiner/projects/AssignInstructors.vue';
import AssignAssessors from './components/Examiner/projects/AssignAssessors.vue';

import ViewLogbooks from "./components/Examiner/logbooks/ViewLogbooks.vue";
import AddLogbook from "./components/Examiner/logbooks/AddLogbook.vue";

import ViewTraineeTable from "./components/Examiner/trainees/ViewTraineeTable.vue";
import AddTrainee from "./components/Examiner/trainees/AddTrainee.vue";
import ImportTrainees from "./components/Examiner/trainees/ImportTrainees.vue";
import TraineeIssuesDisplay from "./components/Examiner/trainees/TraineeIssuesDisplay.vue";
import TraineeImportSuccessful from "./components/Examiner/trainees/TraineeImportSuccessful.vue";

import ViewReports from "./components/Examiner/reports/ViewReports.vue";
import TraineeReports from "./components/Examiner/reports/TraineeReports.vue";
import TraineeReportsHTML from "./components/Examiner/reports/TraineeReportsHTML.vue";


import devDev from "./components/devDev.vue";

const routes = [
  {
    name: "Dev",
    component: devDev,
    path: "/dev",
  },
    {
        name: 'examinerDashboard',
        component: dashBoard,
        path: '/dashboard',
    },
    {
        name: 'AddUser',
        component: AddUser,
        path: '/add-user',
    },
    {
        name: 'ViewUsers',
        component: ViewUsers,
        path: '/view-users',
    },
    {
        name: 'products',
        component: viewProducts,
        path: '/products',
    },
    {
      path: "/products/:productId",
      name: "productPage",
      component: productPage,
    },
    {
      path: "/products/:productId/import-csv",
      name: "importTasks",
      component: importTasksPage,
    },
    {
      path: "/products/:productId/import-csv-B2",
      name: "importTasksB2",
      component: importTasksB2,
    },
    {
      path: "/products/:productId/import-csv/issues",
      name: "IssuesDisplay",
      component: IssuesDisplay,
    },
    {
      path: "/products/:productId/import-csv/issues-B2",
      name: "IssuesDisplayB2",
      component: IssuesDisplayB2,
    },
    {
      path: "/products/:productId/import-csv/import-successful",
      name: "importSuccessful",
      component: importSuccessful,
    },
    {
      path: "/products/:productId/import-csv/import-successful-B2",
      name: "importSuccessfulB2",
      component: importSuccessfulB2,
    },
    {
        name: 'LoginPage',
        component: Login,
        path: '/',
    },
    {
        name: 'TemporaryLogin',
        component: TemporaryLogin,
        path: '/temp-login',
    },
    // {
    //     name:'AddTrainee',
    //     component:AddTrainee,
    //     path:'/add-trainee',
    // },
    // {
    //     name: 'AddInstructor',
    //     component: AddInstructor,
    //     path: '/add-instructor',
    // },
    {
        name: 'ViewTrainees',
        component: ViewTrainees,
        path: '/view-trainees',
    },
    {
        name: 'ViewInstructors',
        component: ViewInstructors,
        path: '/view-instructors',
    },
    // {
    //     name: 'ViewTasks',
    //     component: ViewTasks,
    //     path: '/view-tasks',
    // },
    // {
    //     name: 'EditTrainee',
    //     component: EditTrainee,
    //     path: '/trainees/:trainee_id',
    // },
    // {
    //     name: 'EditInstructor',
    //     component: EditInstructor,
    //     path: '/instructors/:instructor_id',
    // },
    {
        name: 'InstructorHome',
        component: InstructorHome,
        path: '/instructor-home',
    },
    {
        name: 'GenerateQR',
        component: GenerateQR,
        path: '/generate-qr',
    },
    {
        name: 'InstructorLogbook',
        component: InstructorLogbook,
        path: '/instructor-logbook/:traineeId',
    },
    {
      name: 'MultiSignOff',
      component: MultiSignOff,
      path: '/multi-so',
  },
    {
        name: 'TraineeHome',
        component: TraineeHome,
        path: '/trainee-home',
    },
    {
        name: 'TraineeLogbook',
        component: TraineeLogbook,
        path: '/trainee-logbook/:logbookId',
    },
    {
        name: 'AddProject',
        component: AddProject,
        path: '/add-project',
    },
    {
        name: 'ViewProjects',
        component: ViewProjects,
        path: '/projects',
    },
    {
        name: 'ViewTrainees',
        component: ViewTrainees,
        path: '/view-trainees/:project_id',
    },
    {
        name: 'ViewInstructors',
        component: ViewInstructors,
        path: '/view-instructors/:project_id',
    },
    {
        name: 'ViewAssessors',
        component: ViewAssessors,
        path: '/view-assessors/:project_id',
    },
    {
        name: 'AssignTrainees',
        component: AssignTrainees,
        path: '/assign-trainees/:project_id',
    },
    {
        name: 'AssignInstructors',
        component: AssignInstructors,
        path: '/assign-instructors/:project_id',
    },
    {
        name: 'AssignAssessors',
        component: AssignAssessors,
        path: '/assign-assessors/:project_id',
    },
    {
        name: 'AddLogbook',
        component: AddLogbook,
        path: '/add-logbook',
    },
    {
        name: 'ViewLogbooks',
        component: ViewLogbooks,
        path: '/logbooks',
    },
    {
        name: 'ViewTraineeTable',
        component: ViewTraineeTable,
        path: '/trainees',
    },
    {
        name: 'AddTrainee',
        component: AddTrainee,
        path: '/add-trainee',
    },
    {
        name: 'ImportTrainees',
        component: ImportTrainees,
        path: '/import-trainees',
    },
    {
        path: '/import-trainees/issues',
        name: 'TraineeIssuesDisplay',
        component: TraineeIssuesDisplay
    },
    {
        path: '/import-trainees/import-successful',
        name: 'TraineeImportSuccessful',
        component: TraineeImportSuccessful,
    },
    {
        name: 'ViewReports',
        component: ViewReports,
        path: '/reports',
    },
    {
        name: 'TraineeReports',
        component: TraineeReports,
        path: '/trainee-reports',
    },
    {
        name:'TraineeReportsHTML',
        component:TraineeReportsHTML,
        path:'/trainee-reports-html/:trainee_id',
    },
    {
        name:'TimeOut',
        component:TimeOut,
        path:'/timeout',
    },
    {
        name:'TraineeSignature',
        component:TraineeSignature,
        path:'/sign',
    },
    {
        name: 'ExaminerHelp',
        component: ExaminerHelp,
        path: '/help',
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: "history",
  base: "/",
});

export default router;
