<template>
  <div>
    <Header />
    <div class="content">
      <h1>Logbooks:</h1>
      <!-- Display the list of current products -->
      <div class="product-container">
        <div v-for="logbook in logbooks" :key="logbook.logbook_id"
          @click="openLogbook(logbook.logbook_id, logbook.product_id)" class="product-box">
          <div class="product-card">
            <h2><b>Logbook Name: (ID: {{ logbook.logbook_id }}) </b> <br> {{ logbook.name }} </h2>
            <span class="product-name"><b>Product: </b> <br>{{ logbook.productName }}</span>
            <!-- Inside .product-card in your template -->
            <div class="task-summary">
              <p>Total Tasks: {{ logbook.totalTasks }}</p>
              <p>Enabled Tasks: {{ logbook.enabledTasks }}</p>
              <p>Remaining Tasks: {{ logbook.remainingTasks }}</p>
            </div>
            <div class="emails">
              <p class="instructor-email">Instructor: {{ logbook.instructorName }}</p>
              <p class="instructor-email">Email: {{ logbook.instructorEmail }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from './TraineeHeader.vue';
import axios from 'axios';
export default {
  name: 'TraineeHome',
  data() {
    return {
      logbooks: [],
      currentTime: '',
      loggedInUser: '',
      showModal: false,
    };
  },
  components: {
    Header,
  },
  computed: {
    displayedRemainingTasks() {
      return this.logbook.enabledTasks > 0 ? this.logbook.remainingTasks : '-';
    }
  },
  async mounted() {
    try {
      const traineeId = localStorage.getItem('user_id');
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      // Include the trainee_id parameter in the URL
      const result = await axios.get(`/api/trainee/${traineeId}/logbooks`, { headers });
      console.log(result.data.logbooks)
      this.logbooks = result.data.logbooks;
      let instructorIds = [];
      for (const logbook of this.logbooks) {
        const productId = logbook.product_id;
        console.log(logbook, "logbook");
        const productName = await this.fetchproductName(productId, headers);
        logbook.productName = productName;
        localStorage.setItem('productName', logbook.productName);
        const traineeEmail = await this.fetchTraineeEmail(traineeId, headers);
        logbook.traineeEmail = traineeEmail;

        const logbookId = logbook.logbook_id;
        const tasksResult = await axios.get(`/api/${logbookId}/tasks`, { headers });
        logbook.tasks = tasksResult.data.tasks;
        const logbookEntriesResult = await axios.get(`/api/logbookentry/${logbook.logbook_id}`, { headers });
        logbook.totalTasks = tasksResult.data.tasks.length;
        logbook.remainingTasks = logbookEntriesResult.data.tasks.filter(entry => entry.completed == null).length;
        logbook.enabledTasks = logbookEntriesResult.data.tasks.length;

        const usersResult = await axios.get(`/api/user_logbook/${logbook.logbook_id}`, { headers });
        const instructors = usersResult.data.filter(user => user.role === 'instructor');
        console.log("Instructors for logbook:", instructors);

        instructorIds.push(...instructors.map(instructor => instructor.user_id));

        if (instructors.length > 0) {
          const instructorUser = instructors[0];
          const instructorDetails = await this.fetchInstructorEmail(instructorUser.user_id, headers);
          logbook.instructorEmail = instructorDetails.email;
          logbook.instructorName = instructorDetails.name;
        }
        const uniqueInstructorIds = [...new Set(instructorIds)];
        localStorage.setItem('instructorIds', JSON.stringify(uniqueInstructorIds));
      }
      let trainee_id = result.data.logbooks[0].trainee_id;
      localStorage.setItem('trainee_id', trainee_id);
      const loggedInUser = localStorage.getItem('username');
      this.loggedInUser = loggedInUser;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Unauthorized, redirect to login page
        this.$router.push({ name: 'LoginPage' });
      }
    }
  },
  methods: {
    async openLogbook(logbookId, productId) {
      localStorage.setItem('logbook_id', logbookId);
      localStorage.setItem('productId', productId);
      let token = localStorage.getItem('token');
      if (!token) {
        this.$router.push({ name: 'examinerLogin' });
      }
      this.$router.push({ name: 'TraineeLogbook', params: { logbookId, productId } });
    },
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async fetchproductName(productId, headers) {
      try {
        const response = await axios.get(`/api/products/${productId}`, { headers });
        console.log(response.data.name);
        return response.data.name;
      } catch (error) {
        // Handle error if the product name cannot be fetched
        console.error(`Error fetching product name for product ${productId}: ${error.message}`);
        return ''; // Return an empty string as a fallback
      }
    },
    async fetchTraineeEmail(traineeId, headers) {
      try {
        const response = await axios.get(`/api/users/${traineeId}`, { headers });
        const traineeName = response.data.first_name + ' ' + response.data.last_name;
        localStorage.setItem('traineeName', traineeName);  // Save trainee name to local storage
        return {
          email: response.data.email,
          name: traineeName
        };
      } catch (error) {
        console.error(`Error fetching details for trainee ${traineeId}: ${error.message}`);
        return {
          email: '',  // Return an empty string as a fallback for email
          name: ''   // Return an empty string as a fallback for name
        };
      }
    },
    async fetchInstructorEmail(instructorId, headers) {
      try {
        const response = await axios.get(`/api/users/${instructorId}`, { headers });
        const instructorName = response.data.first_name + ' ' + response.data.last_name;
        localStorage.setItem('instructorName', instructorName);  // Save Instructor name to local storage
        return {
          email: response.data.email,
          name: instructorName
        };
      } catch (error) {
        console.error(`Error fetching details for instructor ${instructorId}: ${error.message}`);
        return {
          email: '',
          name: ''
        };
      }
    },
  },
};
</script>
<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  font-weight: bold;
  /* Add space from the top */
}

.product-name {
  font-size: 12px;
  /* Set the font size for the product name */
  color: grey;
  /* Set the text color to grey */
  margin-top: 60px;
  /* Add some space between the logbook name and product name */
  position: absolute;
  margin-left: 0%;
  border: none;
  text-align: start;
}

.trainee-email,
.instructor-email {
  font-size: 0.9rem;
  /* Set the font size for the emails */
  color: grey;
  /* Set the text color to grey */
  margin: 0;
  margin-top: 8px;
  /* Reset margin to remove any default spacing */
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  /* Center products horizontally */
  align-items: center;
  /* Center products vertically */
  margin-top: 10;
  width: 90%;
}

h2 {
  font-size: 16px;
  margin-bottom: 55px;
  margin-left: 0px;
  line-height: 1.5;
}

h1 {
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0px;
  top: 7px;
}

.product-box {
  margin: 20px;
  width: 100%;
  height: 120px;
}

.product-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2),
    /* Right and bottom */
    -3px -3px 3px rgba(0, 0, 0, 0.2),
    /* Left and top */
    0 0 3px rgba(0, 0, 0, 0.2);
  /* Inner shadow */
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  height: 120px;
  /* Center content horizontally within the card */
}

.product-card:hover {
  transform: scale(1.03);
}

.task-summary {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
}

.task-summary p {
  margin-bottom: 2px;
  /* Adjust as needed to reduce the gap */
}</style>