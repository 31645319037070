<template>
    <div class="Trainee-TS-modal-overlay">
        <div class="Trainee-TS-modal-dialog" @click.stop>
            <div class="Trainee-TS-modal-content">
                <div class="Trainee-TS-modal-header">
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 10px;"><b>Trainee Answerbook</b> (Task ID: {{
                        taskId }})
                    </h5>
                </div>
                <div class="container-fluid" v-show="currentTab === 'troubleshoot'">
                    <div class="artificial-fieldset">

                        <div class="question">
                            <div>
                                <div class="artificial-fieldset">
                                    <label class="artificial-fieldset-legend">Documentation Info</label>
                                    <table class="trainee-info">
                                        <thead>
                                            <th>Doc.</th>
                                            <th>Description</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>AC-Reg</td>
                                                <td>Aircraft tail number/registration</td>
                                            </tr>
                                            <tr>
                                                <td>JC/Task-Code</td>
                                                <td>The task card number to which the task was performed</td>
                                            </tr>
                                            <tr>
                                                <td>AMM Reference</td>
                                                <td>The Aircraft Maintenance Manual (AMM) reference used.</td>
                                            </tr>
                                            <tr>
                                                <td>Proof Data</td>
                                                <td>Evidence data such as P/N, S/N, measurement</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="artificial-fieldset" style="margin-top: 30px;">
                                <label class="artificial-fieldset-legend">Trainee Input</label>
                                <table class="trainee-info-table">
                                    <thead>
                                        <th style="width: 9%;">AC-Reg</th>
                                        <th style="width: 28%;">JC/Task-Code</th>
                                        <th style="width: 7%;">AMM Reference</th>
                                        <th>Proof Data</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in documentReferences" :key="index">
                                            <td><input v-model="item.ac_reg" :disabled="isSubmitted"/></td>
                                            <td><input v-model="item.jobcard" :disabled="isSubmitted"/></td>
                                            <td><input v-model="item.amm_ref" :disabled="isSubmitted"/></td>
                                            <td><input v-model="item.proof_data" :disabled="isSubmitted"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="Trainee-modal-footer" style="margin-top: 10px; margin-bottom: 0px;">
                        <button @click="close" class="btn btn-secondary"
                            style="margin-top: 10px; margin-bottom: 20px;">Close</button>
                        <div class="right">
                            <button @click="saveAnswers" class="btn btn-primary" id="save-button" :disabled="isSubmitted">Save</button>
                            <button @click="submitAnswers" class="btn btn-primary" id="submit-button"
                                style="background-color: brown;" :disabled="isSubmitted">Submit</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    name: 'TraineeAnswerbookModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        troubleshoot: String,
        taskExplanation: String
    },
    data() {
        return {
            documentReferences: [
                { ac_reg: '', jobcard: '', amm_ref: '', proof_data: '' }
            ],
            showTroubleshootModal: true,
            editableTroubleshoot: this.troubleshoot,
            currentTab: 'troubleshoot',
            isSubmitted: false // default value
        };

    },

    mounted() {
        this.loadAnswers();
    },
    methods: {
        async loadAnswers() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            try {
                const response = await axios.get(`/api/logbookentry/${this.logbookId}/${this.taskId}`, { headers });
                const storedAnswer = response.data.task.troubleshoot;
                this.editableTroubleshoot = storedAnswer;
                this.is_submitted = response.data.task.is_submitted;
                this.isSubmitted = response.data.task.is_submitted !== 0;
                const ac_reg = response.data.task.ac_reg;
                const jobcard = response.data.task.jobcard;
                const amm_ref = response.data.task.amm_ref;
                const proof_data = response.data.task.proof_data;

                this.documentReferences = [
                    { ac_reg: ac_reg, jobcard: jobcard, amm_ref: amm_ref, proof_data: proof_data }
                ];
            } catch (error) {
                console.error('Failed to load answers', error);
                // Handle the error, show a message, etc.
            }
        },
        close() {
            this.$emit('close');
        },
        saveAnswers() {
            this.saveOrUpdateAnswers(0);
        },
        submitAnswers() {
            if (window.confirm('Once you submit, you cannot edit the answers. Are you sure you want to continue?')) {
                this.saveOrUpdateAnswers(1);
            }
        },
        saveOrUpdateAnswers(isSubmitting) {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            const docRequestData = {
                ac_reg: this.documentReferences[0].ac_reg,
                jobcard: this.documentReferences[0].jobcard,
                amm_ref: this.documentReferences[0].amm_ref,
                proof_data: this.documentReferences[0].proof_data,
                is_submitted: isSubmitting
            };

            console.log(docRequestData);

            axios.put(`/api/logbookentry/${this.logbookId}/${this.taskId}`, docRequestData, { headers })
                .then(docResponse => {
                    console.log('Document reference updated successfully:', docResponse.data);
                    if (isSubmitting) {
                        this.makeEditorReadOnly();
                    }
                    alert('Answer submitted successfully.');
                    this.$emit('close');
                })
                .catch(docError => {
                    console.error('Failed to update document reference:', docError);
                    alert('Failed to update the document reference.');
                });
        },
        makeEditorReadOnly() {
            if (this.quill) {
                this.quill.enable(false); // This disables editing
                this.quill.getModule('toolbar').container.style.display = 'none'; // This hides the toolbar
                this.quill.root.setAttribute('contenteditable', false); // This disables the cursor
            }
        },
    }
}
</script>



<style>
.Trainee-TS-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(97, 94, 94, 0.5);
    z-index: 1000;
    JUSTIFY-CONTENT: CENTER;
    overflow-y: auto;
}

.Trainee-TS-modal-body h2 {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 1%;
    text-align: center;
    overflow-y: auto;

}

.Trainee-TS-modal-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 5%;
}

.Trainee-TS-modal-body p.heading {
    list-style-type: none;
    margin-top: 20px;
    text-align: left;
    margin-left: 1%;
}

.assessment-criteria-box {
    border: 1px solid #b1b1b1;
    /* You can adjust the border color and thickness */
    padding: 10px;
    /* Optional: Add padding for better appearance */
    margin: 10px 0;
    /* Optional: Add margin for spacing */
}


.Trainee-TS-modal-content {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 89vh;
    margin-top: 30px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
}

.Troubleshoot-modal-dialog {
    display: flex;
    flex-direction: row;
    /* Arrange children (questions and answers) in a row */
}

.Trainee-TS-modal-dialog {
    overflow-y: auto;
}

.Troubleshoot-modal-body {
    display: flex;

}

.question-item {
    margin: 10px 0;
    text-align: left;
    font-weight: bold;
}

.Trainee-questions-container>ol>li>ul {
    list-style-type: disc;
    /* Adds a bullet point (dot) */
    padding-left: 40px;
    /* Indent subpoints further than the main list */
}

.Trainee-questions-container ul li {
    margin: 5px 0;
}

.Trainee-questions-container {
    text-align: left;
}

button {
    width: 100px;
    /* Adjust as needed */
    padding: 10px;
    /* Adjust as needed */
    background-color: #f2f2f2;
    /* Style as needed */
    border: none;
    /* Style as needed */
    cursor: pointer;
    /* Style as needed */
    /* Add more styling as needed */
}

.questions-list {
    text-align: left;
}

.Trainee-questions-container h6,
.Trainee-answers-container h6 {
    width: 100%;
    background-color: rgb(216, 216, 216);
    /* Light grey background */
    /* Border for the header */
    text-align: center;

}


.list-group-item {
    background-color: transparent;
    /* Remove default background */
}

.list-group-numbered {
    counter-reset: list-group-counter;
    text-align: left;
}

.list-group-numbered>.list-group-item:before {
    content: counter(list-group-counter) ". ";
    counter-increment: list-group-counter;
}

/* Style for the nested ul */
.Trainee-questions-container ul {
    list-style-type: none;
    /* Removes bullet points */
    padding-left: 20px;
    /* Indent subpoints */
}

.Trainee-answers-container {
    outline: none;
    z-index: 1000;
    width: 60%;
}

.Trainee-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Trainee-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}

.nav-link {
    cursor: pointer;
}

.artificial-fieldset {
    border: 1px solid #8b8b8b;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}


.question {
    text-align: left;
    font-weight: bold;
}

.navigation-buttons {
    margin-top: 5px;
}

.nav-button {
    padding: 5px 15px;
    /* Adjust padding to change button size */
    margin: 0 5px;
    /* Space between buttons */

}


.ql-toolbar {
    height: 40px;
    min-height: 40px;
}


.ql-toolbar .ql-formats {
    margin: 0 2px;
}

.ql-container .ql-editor {
    height: 300px;
    min-height: 200px;
}

h4 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
}

.trainee-info-table input {
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 30px;
    outline: none;
    /* Optional: Remove the outline when the input is focused */
    margin: 0px;
    width: 100%;
}

table.trainee-info {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.trainee-info th,
.trainee-info td {
    border: 1px solid #bbbbbb;
    text-align: left;
    padding: 3px;
    font-size: 14px;
}

.trainee-info td {
    font-weight: 100;
}

.trainee-info th {
    background-color: #ebebeb;
}

.question p {
    font-size: 14px;
    /* Adjust the font size as desired */
    font-weight: lighter;
    /* Adjust the font weight as desired */
}

table.trainee-info-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.trainee-info-table th,
.trainee-info-table td {
    border: 1px solid #bbbbbb;
    text-align: left;
    font-size: 14px;
}

.trainee-info-table td {
    font-weight: 100;
}

.trainee-info-table th {
    background-color: #ebebeb;
    padding: 4px;
}

#save-button {
    margin-bottom: 20px;
}

</style>